<template>
    <div class="bg-white mb-3">
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-3">
            <div>
                <h1 class="h4 mb-0">Order requested</h1>
            </div>
        </div>
        <div>
            <div class="mb-5" v-if="pendingOrderData">
                <div class="summary">
                    <strong>Patient name:</strong> {{ pendingOrderData.patient_name }}<br />
                    <strong>Dentist:</strong> {{ pendingOrderData.dentist.first_name }}
                    {{ pendingOrderData.dentist.surname }}<br />
                    <strong>Practice:</strong> {{ pendingOrderData.practice.company_name }}<br />
                    <span>
                        <strong>Requested delivery date:</strong> {{ $filters.formatDate(pendingOrderData.patient_date) }}
                    </span>
                </div>
            </div>
            <div class="row">
                <p class="h5 mb-3" style="line-height: 40px" v-if="step1options == 'uniqueCode'"><span class="progress-circle">1</span>Identify the order</p>
                <p class="h5 mb-3" style="line-height: 40px" v-if="step1options == 'printLabTicket'"><span class="progress-circle">2</span>Send your order to us using:</p>
               
            </div>
            <div v-if="!showNextStep"
                class="row no-gutters d-flex flex-wrap align-items-stretch mb-3 justify-content-between position-relative card-option">
                <div class="card col shadow-sm" v-if="this.displayCode && step1options === 'uniqueCode'">
                    <div class="card-body d-flex flex-column flex-grow-1">
                        <h5 class="card-title">Use the unique code</h5>
                        <div class="d-flex flex-column flex-grow-1">
                            <p>Write the unique code below on a piece of paper and package this together with your impressions / models.</p>
                            <p class="h4 font-monospace">{{ this.displayCode }}</p>
                            <div><a target="_blank" href="../../resources/pdfs/code-template.pdf">Download our printable code template here</a>
                            <p style="font-size:18px; text-decoration: underline;" class="small mb-1 text-muted alt-option"
                            @click="step1options = 'printLabTicket'">Alternatively, click here to download lab ticket</p>
                        </div>
                            
                        </div>
                    </div>
                </div>
                <div class="card col shadow-sm" v-if="step1options === 'printLabTicket'">
                    <div class="card-body d-flex flex-column flex-grow-1">
                        <h5 class="card-title w-100" v-if="this.displayCode">Print a lab ticket</h5>
                        <h5 class="card-title w-100" v-else>Print a lab ticket</h5>
                        <div class="d-flex flex-column flex-grow-1">
                            <p>Print off the lab ticket below and package this together with your impressions / models.
                            </p>
                            <div class="mt-auto mb-3"><span class="btn btn-primary shadow-sm" @click="openLabTicketPdf">Open Lab Ticket</span></div>
                            <p style="font-size:18px; text-decoration: underline;" class="small mb-1 text-muted alt-option"
                            @click="step1options = 'uniqueCode'">Alternatively, use the unique code</p>  
                                
                        </div>          
                    </div>
                </div>
            </div><!--end flex no-gutters -->

            <div class="row no-gutters d-flex align-items-stretch mb-3 card-option" v-if="showNextStep">
                <span class="pl-0">How would you like to send the order to us?</span>
                <div class="step-2-options d-flex pl-0">
                    <span  @click="step2options = 'returnsLabel'" class="btn shadow-sm me-2" :class="step2options === 'returnsLabel' ? 'btn-primary' : 'btn-light'">Returns Label</span>
                    <span  @click="step2options = 'collection'" class="btn shadow-sm me-2" :class="step2options === 'collection' ? 'btn-primary' : 'btn-light'">Collection</span>
                    <span @click="step2options = 'other'" class="btn shadow-sm" :class="step2options === 'other' ? 'btn-primary' : 'btn-light'">Other</span>
                </div>
                <div class="card col shadow-sm" v-if="step2options === 'returnsLabel'">
                    <div class="card-body d-flex flex-column flex-grow-1">
                        <h5 class="card-title">Returns label</h5>
                        <div class="d-flex flex-column flex-grow-1">
                            <p>To send an order via Royal Mail, simply create a Tracked returns label using the button below and take your parcel to any post office or parcel postbox.</p>
                            <div class="mt-auto">
                                <router-link :to="{ name: 'ReturnLabel' }" target="_blank"
                                    class="btn btn-primary shadow-sm">Create a label</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col shadow-sm"
                    v-if="pendingOrderData && pendingOrderData.practice.collection_method_id != 1 && step2options === 'collection'">
                    <div class="card-body d-flex flex-column flex-grow-1">
                        <h5>Collection</h5>
                        <div class="d-flex flex-column flex-grow-1">
                            <p>If you require this order to be collected by one of our drivers or by third-party courier, please request a collection.</p>
                            <div class="mt-auto"><router-link :to="{ name: 'CollectionCreate' }"
                                    class="btn btn-primary shadow-sm">Request a collection</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card col shadow-sm" v-if="pendingOrderData && step2options === 'other'">
                    <div class="card-body d-flex flex-column flex-grow-1">
                        <h5>Other</h5>
                        <div class="d-flex flex-column flex-grow-1">
                            <p>
                                Swift Dental Group<br />
                                {{ pendingOrderData.company_address.building }}<br />
                                {{ pendingOrderData.company_address.street }}<br />
                                {{ pendingOrderData.company_address.town }}<br />
                                {{ pendingOrderData.company_address.postcode }}<br />
                            </p>
                        </div>
                    </div>
                </div>
            </div><!--end flex no-gutters -->
        </div>
        <hr v-if="step2options === '' && showNextStep" />
        <router-link v-if="showNextStep" to="/orders" class="btn shadow-sm btn-primary me-3">Finish</router-link>
        <button @click="steps" class="btn shadow-sm me-3" :class="showNextStep ? 'btn-secondary' : 'btn-primary'">{{
            showNextStep ? 'Previous' : 'Next' }}</button>
            
    </div>
</template>



<script>
import api from "@/services/api";
import base64 from "@/services/base64";

export default {
    props: {
        id: {
            required: true,
        }
    },
    data() {
        return {
            pendingOrderData: null,
            showNextStep: false,
            showAlternative: false,
            step1options: 'uniqueCode',
            step2options: 'returnsLabel',
        }
    },
    computed: {

    },
    mounted() {
        this.fetchOrder();
    },
    methods: {
        fetchOrder: function () {
            api.get('/api/v1/pending-orders/' + this.id)
                .then((response) => {
                    this.pendingOrderData = response.data.data;
                    this.convertCode();
                })
                .catch(() => {
                    console.log('reached')
                });
        },
        openLabTicketPdf: function () {
            var pdfWindow = window.open();
            pdfWindow.document.title = "Loading...";

            api
                .get('/api/v1/temp-order/' + this.id + '/order-form')
                .then((response) => {
                    var blob = base64.toBlob(response.data, 'application/pdf');
                    pdfWindow.location = URL.createObjectURL(blob);
                })
                .catch((error) => {
                    pdfWindow.close();
                    console.log('Catch pdf error', error);
                    alert('There was an error downloading this ticket, please try again later.');
                });
        },
        steps: function () {
            this.showNextStep = !this.showNextStep;
        },
        convertCode: function () {
            var rawCode = this.pendingOrderData.lab_ticket_code;
            this.displayCode = rawCode.substr(0, 3) + ' - ' + rawCode.substr(3, 3) + ' - ' + rawCode.substr(6)
        },
    },
}

</script>

<style scoped lang="scss">
.summary {
    border-left: 10px solid #eee;
    padding-left: 12px;
}
a {
    text-decoration: none;
}
.pl-0 {
    padding-left: 0;
}
.card {
    margin-top: 10px;

}
.alt-option:hover {
    cursor: pointer;
}
.card-option {
    gap: 10px;
}
.card-option {
    margin-left:0rem;
}


.row.no-gutters>.col {
    padding-right: 0;
    padding-left: 0;
}
.progress-circle {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 2px solid $swift-fresh-green;
  background-color: $swift-fresh-green;
  color: #fff;
  margin-bottom: 5px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  float:left;
  margin-right: 10px;
}
</style>
